import {useTranslation} from 'react-i18next';
import PrimaryInput from '../Common/Inputs/PrimaryInput';
import Dropdown from '../Common/Dropdown';
import CheckboxList from '../Common/CustomCheckbox/CheckboxList';
import {Textarea} from '../Common/Textareas/Textarea';
import {RatingSlider} from '../Event/Session/RateSession';
import {getFormPropertyTranslation} from '.';
import {getPropertyTranslation} from '../Event/EventSupport';
import {useSelector} from 'react-redux';

export const FormTextInput = ({item, dataItem, dataItemIndex, updateFormData, userData}) => {
	const {t} = useTranslation();
	return (
		<PrimaryInput
			id={item.uuid}
			inputCheck={(value) => new RegExp(item.regex)?.test(value)}
			labelKey={getFormPropertyTranslation(item.label, userData.language)}
			placeholderKey={getFormPropertyTranslation(item.placeholder, userData.language)}
			required={item.required}
			disabled={item.readonly}
			defaultValue={dataItem.value}
			clickHandler={(newValue) => updateFormData(dataItemIndex, newValue)}
			type={item.type}
			errorMessage={t('eventRegistrationForm.error.invalidValue')}
			hasError={false}
		/>
	);
};

export const FormNumberInput = ({item, dataItem, dataItemIndex, updateFormData, userData}) => {
	const {t} = useTranslation();
	return (
		<PrimaryInput
			id={item.uuid}
			inputCheck={(value) => {
				return new RegExp(item.regex)?.test(value) && (!item.min || value >= item.min) && (!item.max || value <= item.max);
			}}
			labelKey={getFormPropertyTranslation(item.label, userData.language)}
			placeholderKey={getFormPropertyTranslation(item.placeholder, userData.language)}
			required={item.required}
			disabled={item.readonly}
			defaultValue={dataItem.value}
			clickHandler={(newValue) => updateFormData(dataItemIndex, newValue)}
			type={item.type}
			errorMessage={t('eventRegistrationForm.error.invalidValue')}
			hasError={false}
			min={item.min}
			max={item.max}
		/>
	);
};

export const FormDropdownInput = ({item, dataItem, dataItemIndex, updateFormData, userData, }) => {
	const {t} = useTranslation();
	let dropdownOptions = item?.options?.map((option) => ({
		label: getFormPropertyTranslation(option?.label, userData.language),
		value: option.value,
	}));

	if (item.emptyOption && dropdownOptions) {
		dropdownOptions = [{label: t('common.none'), value: null}, ...dropdownOptions];
	}

	const selectedOption = item.options?.find((option) => option.value === dataItem.value);
	const dropdownValue = selectedOption
		? {
				label: getFormPropertyTranslation(selectedOption.label, userData.language),
				value: dataItem.value,
		  }
		: null;

	return (
		<Dropdown
			id={item.uuid}
			key={item.uuid}
			value={dropdownValue}
			required={item.required}
			disabled={item.readonly}
			labelKey={getFormPropertyTranslation(item.label, userData.language)}
			placeholder={getFormPropertyTranslation(item.placeholder, userData.language)}
			bold={false}
			hasError={false}
			isTranslatedText={true}
			errorMessage={t('eventRegistrationForm.error.invalidValue')}
			options={dropdownOptions}
			onChange={(newValue) => updateFormData(dataItemIndex, newValue.value)}
		/>
	);
};

export const FormCheckboxInput = ({item, dataItemIndex, data, handleCheckboxChange, userData}) => {
	const {t} = useTranslation();
	const checkboxOptions = item.options.map((option) => ({
		onChange: () => handleCheckboxChange(dataItemIndex, option),
		id: option.value,
		text: getFormPropertyTranslation(option.label, userData.language),
		checked: data[dataItemIndex].value.includes(option.value),
	}));

	return (
		<CheckboxList
			options={checkboxOptions}
			hasError={false}
			required={item.required}
			disabled={item.readonly}
			labelKey={getFormPropertyTranslation(item.label, userData.language)}
			errorMessage={t('eventRegistrationForm.error.invalidValue')}
		/>
	);
};

export const FormTextareaInput = ({item, dataItem, dataItemIndex, updateFormData, userData}) => (
	<Textarea
		labelKey={getFormPropertyTranslation(item.label, userData.language)}
		placeholder={getFormPropertyTranslation(item.placeholder, userData.language)}
		id={item.uuid}
		required={item.required}
		disabled={item.disabled}
		defaultValue={dataItem.value}
		maximumCharacterLimit={item.max}
		changeHandler={(newValue) => updateFormData(dataItemIndex, newValue)}
		rows={4}
		onFocus={() => (item.uuid)}
	/>
);

export const FormRatingInput = ({item, dataItem, dataItemIndex, updateFormData, userData}) => (
	<div className="rating-wrapper">
		<RatingSlider
			labelKey={getFormPropertyTranslation(item.label, userData.language)}
			rating={dataItem.value}
			setRating={(newValue) => updateFormData(dataItemIndex, newValue)}
			min={item.min}
			max={item.max}
			required={item.required}
			disabled={item.readonly}
			step={item.step}
		/>
	</div>
);

export const FormDividerInput = ({item, userData}) => {
	const label = getFormPropertyTranslation(item.label, userData.language);
	if (label) {
		return (
			<div className="divider">
				<hr />
				<h4 style={{fontSize: item.fontSize}}>{label}</h4>
				<hr />
			</div>
		);
	}
	return (
		<div className="divider">
			<hr />
		</div>
	);
};

export const FormHtmlInput = ({item, userData}) => (
	<div className="html-container" dangerouslySetInnerHTML={{__html: getFormPropertyTranslation(item.content, userData.language)}} />
);

export const FormSessionInput = ({item, dataItem, dataItemIndex, updateFormData, userData, selectedSessions, }) => {
	const {t} = useTranslation();
	const allSessions = useSelector((state) => state.events.allSessions);
	const userSessions = useSelector((state) => state.events.sessions);
	let sessions = allSessions?.filter((session) => session.sessionType === 'CLOSED');
	if (item.filterByTags?.length > 0) {
		sessions = sessions?.filter((session) => item.filterByTags.includes(session.groupTag));
	}

	let dropdownOptions = sessions
		?.map((session) => {
			const userIsAlreadyInSession = userSessions.some((userSession) => userSession._id === session._id);

			// TODO: Add toggle to show attendeeCount and capacity
			// return {
			// 	label: `${getPropertyTranslation(session?.name, userData.language)} (${session.attendeeCount}/${session.capacity})`,
			// 	value: session._id,
			// 	isDisabled:
			// 		(session.attendeeCount === session.capacity && !userIsAlreadyInSession) ||
			// 		(selectedSessions?.includes(session._id) && session._id !== dataItem.value),
			// };

			return {
				label: `${getPropertyTranslation(session?.name, userData.language)}`,
				value: session._id,
				isDisabled:
					(session.attendeeCount === session.capacity && !userIsAlreadyInSession) ||
					(selectedSessions?.includes(session._id) && session._id !== dataItem.value),
			};
		})

		.sort((a, b) => {
			// Sort disabled sessions to the bottom
			if (a.isDisabled && !b.isDisabled) return 1;
			if (!a.isDisabled && b.isDisabled) return -1;

			// For sessions with the same disabled status, maintain their original order
			return 0;
		});

	if (item.emptyOption && dropdownOptions) {
		dropdownOptions = [{label: t('common.none'), value: null}, ...dropdownOptions];
	}

	const selectedSession = allSessions?.find((session) => session._id === dataItem.value);

	// TODO: Add toggle to show attendeeCount and capacity
	// const dropdownValue = selectedSession
	// 	? {
	// 		label: `${getPropertyTranslation(selectedSession?.name, userData.language)} (${selectedSession.attendeeCount}/${
	// 			selectedSession.capacity
	// 		})`,
	// 		value: dataItem.value,
	// 	}
	// 	: null;

	const dropdownValue = selectedSession
		? {
				label: `${getPropertyTranslation(selectedSession?.name, userData.language)}`,
				value: dataItem.value,
		  }
		: null;

	return (
		<Dropdown
			key={item.uuid}
			id={item.uuid}
			value={dropdownValue}
			required={item.required}
			disabled={item.readonly}
			labelKey={getFormPropertyTranslation(item.label, userData.language)}
			placeholder={getFormPropertyTranslation(item.placeholder, userData.language)}
			bold={false}
			hasError={false}
			isTranslatedText={true}
			errorMessage={t('eventRegistrationForm.error.invalidValue')}
			options={dropdownOptions}
			onChange={(newValue) => updateFormData(dataItemIndex, newValue.value)}
		/>
	);
};
