import axios from 'axios';
import apiUrl from './index';

const source = axios.CancelToken.source();
let config = { cancelToken: source.token };

const pathUrls = {
	getColleaguesStatus: '/getColleaguesStatus',
	featureRequest: '/featureRequest',
	updateDataPrivacyDataStorage: '/updateDataPrivacyDataStorage',
	getPersonalizedStats: '/personalizedStats',
	getDataPrivacySettings: '/getDataPrivacySettings',
	strategyOtp: '/otp',
	otpGenerate: '/generate',
	getMaplesAuthKey: '/maplesAuthKey',
	updateColleagueList: '/updateColleagueList',
	updateConnectionList: '/updateConnectionList',
	removeConnection: '/removeConnection',
	removeColleagueFromList: '/removeColleague',
	searchColleagues: '/searchColleagues',
	getColleagueFilters: '/getColleagueFilters',
	logout: '/logout',
	broadcastAspen: '/broadcastAspen',
};

export async function getMicrosoftLoginUrl() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.microsoftLogin;

	return axios.get(requestUrl, config);
}

export async function getMember(memberId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

/**
 *
 * @param {string} memberId = member id
 * @param {boolean} originalResolution = boolean flag to request the photo in its original resolution or reduced resolution.
 * In the request query, if the variation is not specified, the BE returns, by default, the photo in reduced resolution.
 * @returns
 */
export async function getMemberPhoto(memberId, originalResolution) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + apiUrl.path.photo + `?variation=${originalResolution ? '400' : '200'}`;

	return axios(
		{
			method: 'get',
			url: requestUrl,
			responseType: 'arraybuffer',
		},
		config,
	);
}

export async function memberCompleteTour(memberId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				update: {
					biro: {
						onboardingTutorialCompleted: true,
					},
				},
			},
		},
		config,
	);
}

export async function updateOutlookSettings(memberId, deskSettings, parkingSpaceSettings) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId;

	let iCal = {};

	if (deskSettings !== undefined && deskSettings !== null) iCal.teakTypeWorkplace = deskSettings;
	if (parkingSpaceSettings !== undefined && parkingSpaceSettings !== null) iCal.teakTypeParkingSpace = parkingSpaceSettings;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				update: {
					outlook: {
						iCal,
					},
				},
			},
		},
		config,
	);
}

export async function updateMemberLanguage(memberId, langCode) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				update: {
					language: langCode,
				},
			},
		},
		config,
	);
}

export async function updateMemberShareLocation(memberId, shareLocation) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				update: {
					biro: {
						findMyColleague: {
							enabled: shareLocation,
						},
					},
				},
			},
		},
		config,
	);
}

export async function updateMemberPrivacyOption(memberId, option) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				update: {
					biro: {
						findMyColleague: {
							discoverableFor: option,
						},
					},
				},
			},
		},
		config,
	);
}

export async function memberChangeLastSelectedOffice(memberId, newOfficeId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				update: {
					biro: {
						lastOfficeSelection: newOfficeId,
					},
				},
			},
		},
		config,
	);
}

export async function loggingOut() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.auth + pathUrls.logout;

	return axios(
		{
			method: 'delete',
			url: requestUrl,
		},
		config,
	);
}

export async function updateColleagueList(memberId, list) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + pathUrls.updateColleagueList;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				colleagues: list,
			},
		},
		config,
	);
}

/**
 * Update the connection list of the member.
 * @param {string} memberId = array of colleague ids
 * @param {Array} email = email of new connection
 */
export async function updateConnectionList(memberId, eventName, eventAbbrev, eventId, email) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + pathUrls.updateConnectionList;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				email,
				eventName,
				eventAbbrev,
				eventId,
			},
		},
		config,
	);
}

/**
 * Update the last selected area of member.
 * @param {string} memberId = id of member
 * @param {Array} lastSelectedArea = last selected area of member
 */
export async function updateLastSelectedAreaOfMember(memberId, lastSelectedArea) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				update: {
					biro: {
						lastSelectedArea,
					},
				},
			},
		},
		config,
	);
}
/**
 * Remove the connection from members list.
 * @param {string} connectionId = connection id to be removed
 */
export async function removeFromConnectionList(email) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + pathUrls.removeConnection;

	return axios(
		{
			method: 'delete',
			url: requestUrl,
			data: {
				email
			},
		},
		config,
	);
}

export async function removeColleagueFromList(colleagueId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + colleagueId + pathUrls.removeColleagueFromList;

	return axios(
		{
			method: 'delete',
			url: requestUrl,
		},
		config,
	);
}

export async function searchColleagues(query, fromIndex, limit, bookOnBehalfColleague, wengeEvent, filters) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + pathUrls.searchColleagues;
	const includeMemberInSearch = bookOnBehalfColleague !== undefined && bookOnBehalfColleague !== null;
	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				query: query ? query : '',
				fromIndex,
				limit,
				includeMemberInSearch,
				wengeEvent,
				jobTitle: filters?.jobTitle,
				languagesSpoken: filters?.languagesSpoken,
				interestsTags: filters?.interestsTags,
				sbu: filters?.sbu,
				bu: filters?.bu,
				connectionStatus: filters?.connectionStatus
			},
		},
		config,
	);
}

export async function getColleagueFilters(wengeEvent) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + pathUrls.getColleagueFilters;
	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				wengeEvent
			},
		},
		config,
	);
}


export async function getMaplesAuthKey(memberId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + pathUrls.getMaplesAuthKey;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function generateOTP(email, organization) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.auth + apiUrl.path.strategy + pathUrls.strategyOtp + pathUrls.otpGenerate;

	return axios(
		{
			method: 'get',
			url: requestUrl,
			params: { email, organization },
		},
		config,
	);
}

export async function validateOTP(email, otp) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.auth + apiUrl.path.strategy + pathUrls.strategyOtp + apiUrl.path.validate;

	return axios(
		{
			method: 'get',
			url: requestUrl,
			params: { email, otp },
		},
		config,
	);
}
export async function updateProfileCompletionAPI(memberId, { firstName, surname, phone }) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId;
	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				update: {
					name: {
						first: firstName,
						last: surname,
					},
					phone,
				},
			},
		},
		config,
	);
}
export async function getPersonalizedStats(memberId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + pathUrls.getPersonalizedStats;

	return axios(
		{
			method: 'get',
			url: requestUrl,
			params: { memberId },
		},
		config,
	);
}

export async function getDataPrivacySettings(memberId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + pathUrls.getDataPrivacySettings;

	return axios(
		{
			method: 'get',
			url: requestUrl,
			params: { memberId },
		},
		config,
	);
}

export async function updateDataPrivacyDataStorage(memberId, state) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + pathUrls.updateDataPrivacyDataStorage;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			params: { memberId },
			data: {
				enabled: state,
			},
		},
		config,
	);
}

export async function postFeatureRequest(description, isEventFeedback) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + pathUrls.featureRequest + `?isLCEvent=${isEventFeedback}`;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				description,
			},
		},
		config,
	);
}

/**
 * Gets the status for each colleague in an Array.
 * given they are available at that time&date, then the status is "Available"
 * given they have another booking at that time&date, then the status is "Busy"
 * @param {Array} colleagues = array of colleague ids
 * @param {Object} range = object that contains an start and end in which to check the status.
 * @param {ISO} range.start = iso format date
 * @param {ISO} range.end = iso format date
 */
export function getColleaguesStatus(colleagues, range) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + pathUrls.getColleaguesStatus;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			colleagues,
			range,
		},
	});
}

/**
 * Updates the member profile data
 * @param {Id} memberId = id of member
 * @param {Array[string]} interestsTags = list of interests of user
 * @param {Array[string]} languagesSpoken = list of languages of user
 * @param {Array[object]} socials = socials array of different platforms that are shown inside the user profile (eg linkedin)
 * @param {Array[string]} diet = diet option of user
 * @param {String} biography = biograpgy text added by user
 * @param {Array[string]} industriesTags = list of industries of user
 */
export function updateMemberProfile(memberId, interestsTags, socials, languagesSpoken, diet, biography, industriesTags) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				update: {
					interestsTags,
					socials,
					languagesSpoken,
					diet,
					biography,
					industriesTags,
				},
			},
		},
		config,
	);
}

/**
 * Gets broadcast notifications related to aspen bookings of the user;
 * @param {Id} memberId = id of member
 */
export async function getAspenBroadcastNotifications(memberId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = `${hostUrl + apiUrl.path.member + '/' + memberId + pathUrls.broadcastAspen}`;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

/**
 * @param {id} officeId = id of office to be edited in favorites -> either added or removed
 */
export async function editFavoriteOffice(officeId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const memberId = window.localStorage.getItem('memberID');

	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + apiUrl.path.favoriteOffice;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				favoriteOffice: officeId,
			},
		},
		config,
	);
}
